<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      <v-toolbar-title>
        <v-icon class="white--text" left size="20">mdi-phone</v-icon>
        {{ getCardTitle() }}
      </v-toolbar-title>

      <v-spacer />
      <v-btn icon dark @click="handleCancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <template v-slot:extension>
        <v-tabs v-model="tab" dark>
          <v-tabs-slider color="white"></v-tabs-slider>

          <v-tab v-for="item in tabs" :key="item">{{ item }}</v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tab" style="height: 65vh; overflow-y: auto">
      <v-tab-item>
        <v-card flat :loading="form_loading">
          <v-card-text>
            <ValidationObserver ref="form" v-slot="{ invalid }">
              <ValidationProvider
                name="name"
                rules="max:100"
                v-slot="{ errors }"
              >
                <v-text-field
                  prepend-icon="mdi-account-circle"
                  :label="__('Nome', 'contact')"
                  name="name"
                  type="text"
                  :counter="100"
                  v-model="form.name"
                  :error-messages="errors"
                  :disabled="form_loading"
                />
              </ValidationProvider>

              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="form.type"
                    :prepend-icon="
                      form.type == 'F' ? 'mdi-account' : 'mdi-domain'
                    "
                    :items="[
                      {
                        text: __('Pessoa física', 'contact'),
                        value: 'F',
                      },
                      {
                        text: __('Pessoa jurídica', 'contact'),
                        value: 'J',
                      },
                    ]"
                    :label="__('Tipo', 'contact')"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    :prepend-icon="getGenderIcon(form.gender)"
                    v-model="form.gender"
                    :items="[
                      {
                        text: capitalize($tc('model.interface_uninformed')),
                        value: 'N/A',
                      },
                      {
                        text: __('Masculino', 'contact'),
                        value: 'M',
                      },
                      {
                        text: __('Feminino', 'contact'),
                        value: 'F',
                      },
                    ]"
                    :label="__('Gênero', 'contact')"
                  ></v-select>
                </v-col>
              </v-row>

              <ValidationProvider
                name="email"
                rules="email|max:100"
                v-slot="{ errors }"
              >
                <v-text-field
                  :label="__('Email', 'contact')"
                  name="email"
                  type="email"
                  prepend-icon="mdi-email"
                  :counter="100"
                  v-model="form.email"
                  :error-messages="errors"
                  :disabled="form_loading"
                />
              </ValidationProvider>
              <v-row>
                <v-col cols="12" sm="6">
                  <ValidationProvider
                    name="contact1"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      :label="`${__('Telefone principal', 'contact')}`"
                      name="contact1"
                      type="text"
                      prepend-icon="mdi-phone"
                      v-model="form.contact1"
                      :error-messages="errors"
                      :disabled="form_loading"
                      placeholder="123 456 789"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="6">
                  <ValidationProvider
                    name="contact2"
                    :rules="`is_not:${form.contact1}|length:11`"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      :label="`${__('Telefone secundário', 'contact')}`"
                      name="contact2"
                      type="text"
                      prepend-icon="mdi-phone"
                      v-model="form.contact2"
                      :error-messages="errors"
                      :disabled="form_loading"
                      v-mask="'### ### ###'"
                      placeholder="123 456 789"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <ValidationProvider
                name="fiscal_number"
                rules="length:9"
                v-slot="{ errors }"
              >
                <v-text-field
                  :label="`${__('Documento fiscal', 'contact')}`"
                  prepend-icon="mdi-card-bulleted-outline"
                  name="fiscal_number"
                  type="text"
                  v-model="form.fiscal_number"
                  :error-messages="errors"
                  :disabled="form_loading"
                  v-mask="'#########'"
                />
              </ValidationProvider>
              <ValidationProvider name="iban" rules v-slot="{ errors }">
                <v-text-field
                  :label="`${__('IBAN', 'contact')}`"
                  prepend-icon="mdi-bank"
                  name="iban"
                  type="text"
                  v-model="form.iban"
                  :error-messages="errors"
                  :disabled="form_loading"
                  v-mask="'AA## #### #### ########### ##'"
                />
              </ValidationProvider>

              <ValidationProvider
                name="aditional_info"
                rules="max:255"
                v-slot="{ errors }"
              >
                <v-textarea
                  name="aditional_info"
                  counter="255"
                  :label="`${__('Informação adicional', 'contact')}`"
                  v-model="form.aditional_info"
                  rows="4"
                  prepend-icon="mdi-comment"
                  :error-messages="errors"
                  :disabled="form_loading"
                ></v-textarea>
              </ValidationProvider>
            </ValidationObserver>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat :loading="form_loading">
          <v-card-text>
            <v-row>
              <v-col class="d-flex align-center justify-end mb-4">
                <ScopeProvider scope="contacts.edit">
                  <v-btn
                    color="primary"
                    outlined
                    @click="handleClickNewMorada"
                    :disabled="form_loading"
                    >{{ __('Novo endereço', 'contact') }}</v-btn
                  >
                </ScopeProvider>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-list three-line>
              <v-list-item v-if="items.length === 0 && !loading">
                <v-list-item-content>
                  <v-list-item-title class="text-center grey--text">{{ __('Nenhum endereço encontrado', 'address') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                :disabled="form_loading"
                v-for="(item, i) in items" 
                :key="i"
                @click="handleClickEditMorada(i)"
              >
                <v-list-item-avatar>
                  <v-icon
                    class="lighten-1 white--text"
                    :class="{
                      grey: !item.pivot.is_default,
                      primary: item.pivot.is_default,
                    }"
                  >{{ item.pivot.is_default ? 'mdi-home-floor-1' : 'mdi-home' }}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle v-if="item.pivot.is_default">{{ __('Endereço padrão', 'address') }}</v-list-item-subtitle>
                  <v-list-item-title
                    >{{ item.street }},
                    {{ item.number || "S/N" }}</v-list-item-title>
                  <v-list-item-subtitle
                    >{{ item.country_area.name }},
                    {{
                      item.city.name +
                      (item.city_area && item.city_area.name
                        ? `, ${item.city_area.name}`
                        : "")
                    }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle>{{
                    item.postal_code
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="d-flex flex-row">
                  <v-tooltip bottom v-if="!item.pivot.is_default">
                    <template v-slot:activator="{ on, attrs }"> 
                      <v-btn @click.stop="makeAddressDefault(i)" icon>
                        <v-icon 
                          size="22" 
                          color="grey lighten-1"
                          v-on="on"
                          v-bind="attrs"
                        >
                        mdi-home-floor-1
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ __('Tornar o endereço padrão', 'address') }}</span>
                  </v-tooltip>
                  <ScopeProvider scope="contacts.address.delete">
                    <v-btn @click.stop="confirmDelete(i)" icon>
                      <v-icon size="22" color="grey lighten-1"
                        >mdi-delete</v-icon
                      >
                    </v-btn>
                  </ScopeProvider>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-dialog v-model="dialogdata" width="500px" persistent>
              <address-form
                :init="init"
                @cancel="handleCancelDialogData"
                @submit="handleSubmitlDialogData"
              ></address-form>
            </v-dialog>

            <confirm-action-dialog
              :confirm_action="confirmation"
              @change="confirmation = $event"
              @action="deleteItem()"
              :message="__('O endereço será removido permanentemente', 'address')"
            ></confirm-action-dialog>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <v-divider></v-divider>
    <v-card-actions v-if="checkSubmitPermissions()">
      <v-spacer></v-spacer>
      <v-btn
        @click="handleSubmitForm"
        text
        color="primary"
        :loading="form_submitting"
        >{{ $capitalize($tc("model.interface_save_button")) }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mask } from "vue-the-mask";
import ConfirmActionDialog from "../ConfirmActionDialog";
import AddressForm from "./AddressForm";
import ScopeProvider from "@/components/ScopeProvider";
import i18n from '@/mixins/i18n';

export default {
  mixins: [i18n],
  components: { AddressForm, ConfirmActionDialog, ScopeProvider },
  directives: { mask },
  props: ["id"],
  name: "contact-form",
  data: () => ({
    form: {
      type: "F",
      gender: "N/A",
      phone1: "",
    },
    form_loading: false,
    form_submitting: false,
    tab: 0,
    heigth: "400px",
    items: [],
    loading: false,
    headers: [
      { text: "Distrito", value: "country_area.name" },
      { text: "Concelho", value: "city.name" },
      { text: "Freguesia", value: "city_area.name" },
      { text: "Morada", value: "street" },
    ],
    options: {},
    totalItems: 0,
    confirmation: false,
    delete_id: null,
    delete_loading: false,
    dialogdata: false,
    init: null,
    index: null,
  }),
  methods: {
    capitalize: function (s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    checkSubmitPermissions() {
      if (this.id) {
        return this.$store.getters["auth/getScope"]("contacts.edit");
      } else {
        return this.$store.getters["auth/getScope"]("contacts.add");
      }
    },
    async deleteItem() {
      const id = this.delete_id;
      try {
        this.delete_loading = true;
        this.items.splice(id, 1);
        this.confirmation = false;
      } catch (e) {
      } finally {
        this.delete_loading = false;
      }
    },
    confirmDelete(id) {
      this.delete_id = id;
      this.confirmation = true;
    },
    resetList() {
      this.items = [];
      this.totalItems = 0;
    },
    handleClickNewMorada() {
      this.dialogdata = true;
    },
    handleClickEditMorada(index) {
      this.index = index;
      this.init = this.items[index];
      this.dialogdata = true;
    },
    makeAddressDefault(index) {
      this.items = this.items.map((rec, rec_index) => {
        if (rec_index === index) {
          return {
            ...rec,
            pivot: {
              is_default: true
            }
          }
        }

        return {
            ...rec,
            pivot: {
              is_default: false
            }
          }
      })
    },
    handleCancelDialogData() {
      this.init = null;
      this.dialogdata = false;
    },
    handleSubmitlDialogData(data) {
      if (this.init) {
        this.items[this.index] = data;
      } else {
        this.items.push({
          ...data,
          pivot: {
            is_default: this.items.length === 0 // Se não tiver nenhum endereço, tornar o primeiro padrão
          }
        });
      }
      this.dialogdata = false;
      this.init = null;
      this.index = null;
    },
    handleSubmit() {
      this.$emit("submit", this.items);
    },
    fetchContact(id) {
      return this.$http.get(`/contacts/${id}`);
    },
    storeContact(payload) {
      return this.$http.post(`/contacts`, payload);
    },
    updateContact(payload, id) {
      return this.$http.put(`/contacts/${id}`, payload);
    },
    async getContact(id) {
      this.form_loading = true;

      try {
        const response = await this.fetchContact(id);
        this.form = response.data;
        this.items = response.data.addresses;
        this.tab = 0;
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: this.__('Falha ao carregar contato', 'contact'),
          color: "red",
        });
      } finally {
        this.form_loading = false;
      }
    },
    getGenderIcon(gender) {
      switch (gender) {
        case "M":
          return "mdi-gender-male";
        case "F":
          return "mdi-gender-female";
      }
    },
    resetForm() {
      this.form = {
        type: "F",
        gender: "N/A",
        phone1: "",
      };
      this.items = [];
      this.totalItems = 0;
      if (this.$refs.form) this.$refs.form.reset();
    },
    async handleSubmitForm() {
      const validation = await this.$refs.form.validate();

      if (validation) {
        const addresses = this.items.map((address) => {
          address.country_area_id = address.country_area.id;
          address.city_id = address.city.id;
          address.city_area_id = address.city_area?.id;

          return { ...address };
        });

        const payload = {
          ...this.form,
          addresses,
        };

        this.form_loading = true;
        this.form_submitting = true;

        try {
          let response = null;
          let message = this.__('Contato atualizado com sucesso', 'contact');
          let new_response = null;

          if (this.id) {
            new_response = await this.updateContact(payload, this.id);
          } else {
            message = this.__('Contato criado com sucesso', 'contact');
            new_response = await this.storeContact(payload);
          }
          this.resetForm();
          // this.tab = 1;
          this.$emit("submit", new_response.data);
          this.$store.commit("sendMessage", {
            text: message,
          });
        } catch (e) {
          if (e.response) {
            switch (e.response.status) {
              case 422:
                this.$refs.form.setErrors(e.response.data.errors);
                break;

              case 400:
                this.$store.commit("sendMessage", {
                  text: `(${e.response.status}) ${e.response.data.message}`,
                  color: "red",
                });
                break;

              default:
                this.$store.commit("sendMessage", {
                  text: this.__('Algo deu errado, tente novamente mais tarde', 'contact'),
                  color: "red",
                });
                break;
            }
          } else {
            throw e;
          }

          this.tab = 0;
        } finally {
          this.form_loading = false;
          this.form_submitting = false;
        }
      } else {
        this.tab = 0;
      }
    },
    handleCancel() {
      this.tab = 0;
      this.$emit("cancel");
    },
    getCardTitle() {
      return this.id ? this.form.name || this.__('Contatos', 'contact') : this.__('Novo contato', 'contact');
    },
  },
  created() {
    if (!this.id) {
      // this.resetForm();
    } else {

      this.getContact(this.id);
    }
  },
  computed: {
    tabs: function (value) {
      return [
        this.__('Dados pessoais', 'contact'),
        this.__('Endereços', 'contact'),
      ];
    },
  },
  watch: {
    id: function (value) {
      if (!value) {
        this.resetForm();
      } else {
        this.getContact(value);
      }
    },
  },
};
</script>
